import useCustomMutation from "@/Admin/Shared/Helpers/hooks/useCustomMutation";

export default function useUpdateCourse({ courseId, onSettled }) {
  const { mutate, isPending } = useCustomMutation({
    url: "course/update",
    invalidate: [["course", courseId], "courses"],
    method: "post",
    successMsg: "تم تعديل الكورس بنجاح",
    onSettled,
  });

  function updateCourse(data) {
    const formData = new FormData();
    formData.append("id", courseId);
    formData.append("name", data.name); 
    formData.append("description", data.description); 
    formData.append("duration", data.duration); 
    formData.append("price", data.price);

    if (data.image?.[0]) {
      formData.append("image", data.image[0]); 
    }

    mutate(formData);
  }

  return { updateCourse, isPending };
}
