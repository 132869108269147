export let data = [
  {
    image: `https://api.slingacademy.com/public/sample-photos/1.jpeg`,
    duration: 10,
    name: `course 1`,
    subscriptionsCount: 100,
    modulesCount: 50,
    description: "هذا هو النص الذي سيظهر في وصف الكورس",
    price: 150,
    id: 1,
  },
  {
    image: `https://api.slingacademy.com/public/sample-photos/2.jpeg`,
    duration: 10,
    name: `course 2`,
    subscriptionsCount: 100,
    modulesCount: 50,
    description: "هذا هو النص الذي سيظهر في وصف الكورس",
    price: 150,
    id: 2,
  },
  {
    image: `https://api.slingacademy.com/public/sample-photos/3.jpeg`,
    duration: 10,
    name: `course 3`,
    subscriptionsCount: 100,
    modulesCount: 50,
    description: "هذا هو النص الذي سيظهر في وصف الكورس",
    price: 150,
    id: 3,
  },
];

export function addCourse(course) {
  console.error("course is added");

  data = [
    ...data,
    {
      ...course,
      id: data.length + 1,
      image: `https://api.slingacademy.com/public/sample-photos/${
        data.length + 1
      }.jpeg`,
      subscriptionsCount: 100,
      modulesCount: 50,
    },
  ];
}

export function resetCourses() {
  data = [
    {
      image: `https://api.slingacademy.com/public/sample-photos/1.jpeg`,
      duration: 10,
      name: `course 1`,
      subscriptionsCount: 100,
      modulesCount: 50,
      description: "هذا هو النص الذي سيظهر في وصف الكورس",
      price: 150,
      id: 1,
    },
    {
      image: `https://api.slingacademy.com/public/sample-photos/2.jpeg`,
      duration: 10,
      name: `course 2`,
      subscriptionsCount: 100,
      modulesCount: 50,
      description: "هذا هو النص الذي سيظهر في وصف الكورس",
      price: 150,
      id: 2,
    },
    {
      image: `https://api.slingacademy.com/public/sample-photos/3.jpeg`,
      duration: 10,
      name: `course 3`,
      subscriptionsCount: 100,
      modulesCount: 50,
      description: "هذا هو النص الذي سيظهر في وصف الكورس",
      price: 150,
      id: 3,
    },
  ];
}
