import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import { useMutation, useQueryClient } from "@tanstack/react-query";

export default function useUpdateModuleData({ closeModal }) {
  const instance = useAxiosConfig();

  function updateModule(data) {
    return instance.post("module/update", data);
  }

  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: updateModule,
    onSuccess: () => {
      queryClient.invalidateQueries(["course modules"]);
      notifySuccess("تم تعديل الحصة بنجاح");
    },
    onError: (error) => {
      notifyError(error?.response?.data?.message);
    },
    onSettled: () => {
      closeModal();
    },
  });
}
