import React from "react";
import styles from "./ProgressUpload.module.css";

const ProgressUpload = ({ percentage, title }) => {
  // Calculate dynamic color based on percentage
  const hue = (percentage / 100) * 120; // Green to red gradient
  const color = `hsl(${hue}, 100%, 50%)`;

  return (
    <div className={styles.container}>
      <div
        className={styles.progressBar}
        style={{
          width: `${percentage}%`,
          backgroundColor: color,
          transition: "width 3s ease-in-out",
        }}
      >
        {percentage}%
      </div>
      <div style={{ fontSize: "18px" }}>uploading :{title}</div>
    </div>
  );
};

export default ProgressUpload;
