import { useMutation, useQueryClient } from "@tanstack/react-query";
import { notifyError, notifySuccess } from "@shared/Toast/toast";
import useAxiosConfig from "@shared/Helpers/axios/useAxiosConfig";

export default function useCreateStudent() {
  const instance = useAxiosConfig();

  function createStudent(data) {
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };

    return instance.post(`register`, data, config);
  }
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data) => createStudent(data),
    onSuccess: () => {
      queryClient.invalidateQueries(["create student"]);
      notifySuccess("تم انشاء طالب جديد");
    },
    onError: (err) => {
      notifyError(err?.response?.data?.message);
    },
  });
}
