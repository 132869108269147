import { Box, Typography } from '@mui/material'
import React from 'react'

export const HeaderInfo = ({name, value, icon, color}) => {
  return (
    <Box sx={{display:"flex", flexDirection:'column', alignItems:"center", gap:2}}>
        <img src={icon}/>
        <Typography fontSize={20} color={color}>{value}</Typography>
        <Typography color={color}>{name}</Typography>
    </Box>
  )
}
